

<div class="p-2">
  <h1 matDialogTitle>Confirm</h1>
  <h2 mat-dialog-content>{{confirmMessage}}</h2>
  <div class="pt-24" mat-dialog-actions fxLayoutAlign="center center">
    <button (click)="dialogRef.close(true)" class="mat-accent mr-16" mat-raised-button>Confirm</button>
    <button (click)="dialogRef.close(false)" class="mat-warn" mat-raised-button>Cancel</button>
  </div>
</div>
