import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {WebApiHttp} from "../../../../@pristine/process/WebApiHttp.services";
import {SessionManageMent} from "../../../../@pristine/process/SessionManageMent";
import {ValidateResponse} from "../../../../@pristine/process/ValidateResponse";
import {ActivatedRoute, Router} from "@angular/router";
import {isArray} from "rxjs/internal-compatibility";
import {FormControl} from "@angular/forms";
import {pristineSidebarService} from "../../../../@pristine/components/sidebar/sidebar.service";
import {navigation} from "../../../navigation/navigation";
import {pristineConfigService} from "../../../../@pristine/services/config.service";
import {SignalR} from "../../../../@pristine/process/SignalR";
import {Purchaseordermodel} from "../../../modal/purchaseordermodel";
import {MatTableDataSource} from "@angular/material/table";
import {ToastrService} from "ngx-toastr";
import { locationModel } from 'src/app/modal/locationModel';
import {ReportDownload} from "../../../../@pristine/process/report-download";
import {DashboardModule} from "../../../main/dashboard/dashboard.module";
import {PosdashboardComponent} from "../../../main/dashboard/POSdashboard/posdashboard.component";

import {receiverNotification} from "../../../modal/SignalRModel";

import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  navigation: any;
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  private _unsubscribeAll: Subject<any>;

  location:string = this._sessionManagement.getLocationId;
  loadding: boolean = false;
   locationModel : Array<locationModel>=[];
  downList: Array<any>=[];
  notifications: Array<any>=[];
  pendingAcknowledgement:any =''
  constructor(
    private _pristineConfigService: pristineConfigService,
    private _pristineSidebarService: pristineSidebarService,
    public _sessionManagement: SessionManageMent,
    public  webApiHttp: WebApiHttp,
    public validateResponse: ValidateResponse,
    private _signalR: SignalR,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public downloader: ReportDownload,
  private _toster: ToastrService,
  ) {
// console.log(this._sessionManagement.getLocationId)
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }


  ngOnInit(): void {
    // Subscribe to the config changes
    this._pristineConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );

    if(this._sessionManagement.getIsHo=='1')
    this.getLocationList();
    this.downloadingFunction()
    receiverNotification.subscribe(res=>{
      this.getNotifications()
      console.log(res)
    })
    // console.log(this._signalR.receiveDataFromServerListener())
  }

  LogoutFrom_Server() {
    this.webApiHttp.Post(this.webApiHttp.ApiURLArray.Logout, {
      Email: this._sessionManagement.getEmail
    }).then((result: Array<{
      condition: string;
      action: string;
      email_id: string;
      connection_id: string;
    }>) => {
      if (isArray(result) && this.validateResponse.checkArrayResponseCondition(result)) {
        try {
          this._signalR.stopSignalRConnection();
          localStorage.clear();
        } catch (e) {
        } finally {
          window.location.reload();
        }
      }
    }, err => {

    }).then(err1 => {

    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  toggleSidebarOpen(key): void {
    this._pristineSidebarService.getSidebar(key).toggleOpen();
  }

  AddNewUser() {
    this._router.navigateByUrl('/pages/auth/register-2');
  }

  search(value): void {

  }

  selLocation(location : any){
    this._sessionManagement.setLocationId(location.location_id);
    this._sessionManagement.setLocationNameSession(location.location_name);
      this._router.navigateByUrl('/dashboard/posDashBoard');

    // window.location.reload();

  }

  getNotifications(id='', flag='get'){
    let json ={
      flag: flag,
      id: id,
      email_id: this._sessionManagement.getEmail
    }
    this.webApiHttp.Post(this.webApiHttp.ApiURLArray.notificationData,json).then(res=>{
      if(res.length>0 && res[0]?.condition.toLowerCase() == 'true'){
        this.notifications= res;
        this.pendingAcknowledgement =0
        for(let i =0;i<this.notifications.length;i++){
          if(this.notifications[i]?.acknowledgement==0){
            this.pendingAcknowledgement++
          }
        }
      }else{
        this.notifications =[]
      }
    }).catch(err=>{
      console.log(err)
    })
  }

  getLocationList() {
    this.loadding = true;
    if(this._sessionManagement.getEmail!=null && this._sessionManagement.getEmail!=undefined && this._sessionManagement.getEmail!=''){
      this.webApiHttp.Get(this.webApiHttp.ApiURLArray.pos_GetStoreLocationList+this._sessionManagement.getEmail).then((result) => {
        var response: Array<locationModel> = result;
        if (response.length > 0 && response[0].condition.toLowerCase() == 'true') {
          this.locationModel = response;
          if(this._sessionManagement.getLocationId == '' || this._sessionManagement.getLocationId ==null) {
            this.selLocation(this.locationModel[0]);
          }
          this.location = this._sessionManagement.getLocationId;

        } else {
          //this._toster.error(result.length > 0 ? result[0].message : result.message, 'Error');
        }
      }).catch(error => {
      }).finally(() => {
        this.loadding = false;
      });
    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  downloadingFunction(){
    this.downloader.downloadingArray.subscribe(ele => {
      return this.downList = ele
    })
  }

  startDownload(id: any, index) {
    this.downloader.downloadFile(id,index)
  }

  deleteLine(id: any, i: number) {
    this.downloader.deleteFile(id,i)
  }

  get downloadedCount(){
    let x = ''
    this.downloader.readyToSaveCount.subscribe(res=> {
      x = res.toString()
    })
    return x
  }

  colorArray=['#580c82','#8F30A1','#FE4773','#46B3A5','#2E6D92']


  markRead(id: any) {
    this.getNotifications(id,'acknowledgement')
  }

  copyTxt(index: number,tooltip: MatTooltip,clicked) {
    if(!clicked)
      return
    this.downloader.copyText(index)
    tooltip.show()
    tooltip.message='Link Copied'
    setTimeout(() => {
      tooltip.hide()
      tooltip.message = 'Copy Link'
    }, 2000);
  }

  // cpyHover(tooltip: MatTooltip){
  //   tooltip.message = 'Copy Link'
  // }

}
